import { Image } from "./image";
import React, { useState } from "react";

export const Gallery = (props) => {
  const [selectedImage, setSelectedImage] = useState(null);

  const handleImageClick = (largeImage) => {
    setSelectedImage(largeImage);
  };

  const closeImage = () => {
    setSelectedImage(null);
  };

  return (
    <div id="portfolio" className="text-center">
      <div className="container">
        <div className="section-title">
          <h2>Galeria</h2>
          <p>
          Explore nossa galeria de imagens do App Aplicontroll, um sistema web robusto e eficaz. Cada imagem fornece uma visão sobre a interface intuitiva e as funcionalidades poderosas que nossos usuários desfrutam diariamente. Este software foi projetado para facilitar a gestão, proporcionando resultados notáveis em eficiência e produtividade.
          </p>
        </div>
        <div className="row">
          <div className="portfolio-items">
            {props.data
              ? props.data.map((d, i) => (
                  <div
                    key={`${d.title}-${i}`}
                    className="col-sm-6 col-md-4 col-lg-4"
                  >
                    <div onClick={() => handleImageClick(d.largeImage)}>
                      <Image
                        title={d.title}
                        smallImage={d.smallImage}
                      />
                    </div>
                  </div>
                ))
              : "Carregando..."}
          </div>
        </div>
        {selectedImage && (
          <div onClick={closeImage} style={{
            position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', background: 'rgba(0, 0, 0, 0.8)', display: 'flex', alignItems: 'center', justifyContent: 'center', zIndex: 1000
          }}>
            <img src={selectedImage} alt="Large View" style={{maxWidth: '68%', maxHeight: '68%', cursor: 'pointer'}} />
          </div>
        )}
      </div>
    </div>
  );
};
